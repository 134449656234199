<script lang='ts' setup>
import Button from '@/Components/Button/Button.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { defineProps, inject, ref } from 'vue';

const route = inject('route') as typeof import('ziggy-js').default;
const props = defineProps({
    form: Object,
});

const initialValue = ref(props.form?.email ?? '');
const previousValue = ref('');
const isValidatingEmail = ref(false);
const hasValidatedEmail = ref(false);

const validateEmailForm = useForm({
    email: '',
    code: '',
});

const validateEmail = (resend = false) => {
    if (!isValidatingEmail.value || resend) {
        validateEmailForm
            .transform((data) => ({
                email: props.form?.email,
                code: props.form?.email_verification_code,
            }))
            .post(route('email-verifications.store'), {
                preserveScroll: true,
                preserveState: true,
                onError: (errors) => (props.form.errors = mapErrors(errors)),
                onSuccess: () => {
                    isValidatingEmail.value = true;
                    props.form.errors.email = [];
                },
            });
    } else {
        validateEmailForm
            .transform((data) => ({
                code: props.form?.email_verification_code,
            }))
            .put(route('email-verifications.update', { email_verification: props.form?.email }), {
                preserveScroll: true,
                preserveState: true,
                onError: (errors) => (props.form.errors = mapErrors(errors, { email_verification_code: 'code' })),
                onSuccess: () => {
                    hasValidatedEmail.value = true;
                    props.form.errors.email = [];
                },
            });
    }
};

function reset() {
    isValidatingEmail.value = false;
    hasValidatedEmail.value = false;
    props.form.errors.email = [];
    props.form.errors.email_verification_code = [];
}

function handleEmailInput(arg: any) {
    if (arg !== previousValue.value) {
        reset();
    }
    previousValue.value = arg;
}
</script>

<template>
    <FormKit
        v-model="form.email"
        type="text"
        name="email"
        :suffix-icon="hasValidatedEmail ? 'check' : ''"
        :label="$t('Email')"
        :errors="form.errors.email"
        :value="form?.email"
        @input="handleEmailInput"
    />

    <FormKit
        v-if="isValidatingEmail && !hasValidatedEmail"
        v-model="form.email_verification_code"
        type="text"
        name="email_verification_code"
        :label="$t('Paste or type the code we sent a code to your e-mail.')"
        :errors="form.errors.email_verification_code"
    />

    <div class="grid gap-4">
        <Button
            v-if="!hasValidatedEmail"
            type="button"
            :recently-successful="validateEmailForm.recentlySuccessful"
            classes="w-full"
            orange
            :processing="validateEmailForm.processing"
            full-width
            :text="$t('Verify {attribute}', { attribute: $t('E-mail') })"
            :disabled="initialValue === form?.email"
            @click="validateEmail()"
        />

        <Button
            v-if="isValidatingEmail && !hasValidatedEmail"
            classes="my-4"
            full-width
            green
            type="button"
            :processing="validateEmailForm.processing"
            :text="$t('Resend {attribute}', { attribute: $t('E-mail') })"
            @click="validateEmail(true)"
        />
    </div>
</template>
